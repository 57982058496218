import '../global.css'

import * as React from 'react'
import BasePage from '../components/basePage'
import { FlexColumn } from '../components/flex'

const AboutPage = () => {
    return (
        <BasePage pageTitle='About'>
            <FlexColumn>
                <h1>About</h1>
                <p>In the Autumn of 2017 the Vivid Fermentation Project was unknowingly started by way of creative fermentation in the bucolic Pioneer Valley of western Massachusetts. As the years have passed, I began to organize these fermentation efforts under the name <i>Vivid</i>.</p>
                <p>Through creative fermentation processes, careful brewing and inspiration from the natural beauty of my true home - New England, I strive to create delicious, novel and memorable beer. Beer that’s vivid.</p>
                <p>Vivid is not a brewery; it lives on as the very same thing it began - an amature project of fermentation.</p>
                <p>- cameron</p>
            </FlexColumn>
        </BasePage>
    );
}

export default AboutPage
